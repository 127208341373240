import { createContext, useContext, useState } from "react";

const AppContext = createContext();

export const useAppContext = () => {
  return useContext(AppContext);
};

export const AppProvider = ({ children }) => {
  const [userAddress, setUserAddress] = useState(null);
  const [userEns, setUserEns] = useState(null);
  const [provider, setProvider] = useState(null);
  const [web3Modal, setWeb3Modal] = useState(null);

  const value = {
    userAddress,
    setUserAddress,
    userEns,
    setUserEns,
    provider,
    setProvider,
    web3Modal,
    setWeb3Modal,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
